<template>
    <div>
        <div v-show="this.$route.meta.showRole" v-loading="show">
            <div class="condition">
                <div>
                    <el-button class="buttons" icon="el-icon-plus" @click="dialogFormVisible = true">
                        新增
                    </el-button>
                    <el-button class="buttons_download import" icon="el-icon-upload2" @click="getQueryForm()">
                        下载导入模板
                    </el-button>
                    <el-button @click="tabdialogVisible()" class="buttons_download import" icon="el-icon-download">
                        导入文件新增
                    </el-button>
                    <el-button class="buttons_download import" icon="el-icon-upload2" @click="alarmBatchReset()">
                        设置警告流量
                    </el-button>
                </div>
            </div>
            <!-- table表格 -->
            <div class="table">
                <el-table stripe :header-cell-style="{ background: '#E8EAEF', color: '#3D3D3D' }" ref="multipleTable"
                    :data="tableData" tooltip-effect="dark" style="width: 100%"
                    :header-row-class-name="tableRowClassName" @selection-change="handleSelectionChange">
                    <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
                    <el-table-column prop="assetCode" label="资产编码" show-overflow-tooltip>
                        <template slot-scope="scope">
                            <span @click="jumpDetails(scope.row)">{{
                                    scope.row.assetCode
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceCode" label="设备编码" width="120">
                        <template slot-scope="scope">
                            {{ scope.row.deviceCode }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceImei" label="设备IMEI" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.deviceImei }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceImsi" label="设备IMSI" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.deviceImsi }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="deviceIccid" label="设备ICCID" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.deviceIccid }}
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="deviceMsisdn" label="设备MSISDN" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.deviceMsisdn }}
                        </template>
                    </el-table-column> -->
                    <el-table-column label="SIMI卡状态" width="60" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.simStatus == 1 ? "已激活" : "未激活" }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="serviceProvider" label="运营商" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.serviceProvider }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="flowPackage" label="套餐" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.flowPackage }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="activationTime" label="激活时间" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.activationTime }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="endTime" label="到期时间" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.endTime }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="usedFlow" label="已用流量(Mb)" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.usedFlow }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="totalFlow" label="总流量(Mb)" show-overflow-tooltip>
                        <template  slot-scope="scope" >
                            {{ scope.row.totalFlow }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="warnFlow" label="流量告警阈值(Mb)" show-overflow-tooltip>
                        <template v-if="scope.row.warnFlow == null" slot-scope="scope">
                            0
                        </template>
                        <template v-else slot-scope="scope">
                            {{ scope.row.warnFlow }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ofWarn" label="是否触发报警" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.ofWarn == 0 ? "是" : "否" }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="synTime" label="同步时间" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.synTime }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip>
                        <template slot-scope="scope">
                            {{ scope.row.createTime }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="assetCode" label="操作">
                        <template slot-scope="scope">
                            <el-button type="text" class="edit el-icon-edit" size="small"
                                @click="handleDelete(scope.row)">
                                删除 </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- 分页器 -->
            <div class="app-page-container">
                <span @click="first">首页</span>
                <el-pagination @current-change="handleCurrentChange" layout="pager" :hide-on-single-page="false"
                    :page-size="pageSize" :current-page="currentPage" :total="recordCount">
                </el-pagination>
                <span @click="last">尾页</span>
            </div>


            <!-- 新增弹框 -->
            <el-dialog title="新增" :visible.sync="dialogFormVisible">
                <el-form ref="rulesForm" :model="form" :rules="rules">
                    <el-form-item label="设备IMEI:" :label-width="formLabelWidth" prop="deviceImei">
                        <el-input v-model="form.deviceImei" autocomplete="off" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="设备IMSI:" :label-width="formLabelWidth" prop="deviceImsi">
                        <el-input v-model="form.deviceImsi" autocomplete="off" placeholder="请输入内容"></el-input>
                    </el-form-item>

                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="remSubmission(1)">取 消</el-button>
                    <el-button type="primary" @click="addSubmission()">确 定</el-button>
                </div>
            </el-dialog>

            <!-- 导入弹框 -->
            <el-dialog title="请选择上传文件" :visible.sync="dialogVisible" width="30%" @close="handleClose">
                <el-upload class="upload-demo" accept=".xlsx,.xls" :show-file-list="true" :on-change="onChange"
                    :http-request="httpRequest" action="url" :limit="1" :auto-upload="true" :file-list="fileList">
                    <el-button size="small" type="primary">点击上传</el-button>
                </el-upload>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="uploading">关闭</el-button>
                </span>
            </el-dialog>

            <!-- 设置警告流量弹框 -->
            <el-dialog title="设置警告流量" :visible.sync="dialogFlowVisible">
                <el-form ref="rulesForm" :model="form" :rules="rules">
                    <el-form-item label="设备IMSI开始区间:" :label-width="formLabelWidth" prop="deviceImsiStart">
                        <el-input v-model="form.deviceImsiStart" autocomplete="off" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="设备IMSI结束区间:" :label-width="formLabelWidth" prop="deviceImsiEnd">
                        <el-input v-model="form.deviceImsiEnd" autocomplete="off" placeholder="请输入内容"></el-input>
                    </el-form-item>
                    <el-form-item label="流量报警阈值" :label-width="formLabelWidth" prop="warnFlow">
                        <el-input-number v-model="form.warnFlow" controls-position="right" @change="handleChange"
                            :min="1" :max="10"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="remSubmission(2)">取 消</el-button>
                    <el-button type="primary" @click="flowSubmission()">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
  
<script>
import {
    getsimcardAdmin,
    getaddSubmission,
    getImportNew,
    getDownloadImport,
    deleteDeviceBatch,
    getAlarmFlow
} from "../../api/simcardAdmin.js";

export default {
    name: "index",
    data() {
        return {
            allocation: true,
            token: localStorage.getItem("token"),
            userId: localStorage.getItem("userId"),
            coldChainAMHead: localStorage.getItem("coldChainAMHead"),
            pageSize: 12, //每页数据条数
            recordCount: 0, //总页数
            currentPage: 1, //当前页数
            tableData: [],
            //总数据
            tableData: [],
            multipleSelection: [],
            deviceCode: "",
            dialogFormVisible: false,
            dialogVisible: false,
            dialogFlowVisible: false,
            form: {
                deviceImei: '',
                deviceImsi: '',
                deviceImsiEnd: '',
                deviceImsiStart: '',
                warnFlow: 1,
            },
            formLabelWidth: '150px',
            rules: {
                deviceImei: [
                    { type: 'string', required: true, message: "当前内容必填", trigger: 'blur' },
                ],
                deviceImsi: [
                    { type: 'string', required: true, message: "当前内容必填", trigger: 'blur' },
                ],
                deviceImsiEnd: [
                    { type: 'string', required: true, message: "当前内容必填", trigger: 'blur' },
                    // { pattern: "/^[\u0391-\uFFE5A-Za-z]+$/", required: true, message: "请输入中文", trigger: "blur" }
                ],
                deviceImsiStart: [
                    { type: 'string', required: true, message: "当前内容必填", trigger: 'blur' },
                    // { pattern: "/^([1-9][0-9]{0,1}|100)$/", required: true, message: "请输入阿拉伯数字", trigger: "blur" }
                ],
                warnFlow: [
                    { required: true, message: "内容必填", trigger: 'change' },
                    // { pattern: "/^([1-9][0-9]{0,1}|100)$/", required: true, message: "1至100的数", trigger: "blur" }
                ]
            },
            fileList: [],
            formTime: {
                activationTime: '',
                endTime: '',
                synTime: '',
                createTime: '',
            },
            show:false,
        };
    },
    created() {
        this.warningQuerys(false);
        this.clearForm()
    },
    methods: {
        //跳转设备详情页
        jumpDetails(data) {
            if (data.deviceRfId) {
                this.$router.push({
                    name: "详情",
                    params: {
                        deviceRfId: data.deviceRfId
                    }
                });
            }
        },
        //上传
        uploading() {
            this.warningQuerys(false);
        },
        //将后端返回的二进制文档转为xls
        getOutExcel(fileName, res) {
            let blob = new Blob([res], { type: 'application/xls' });
            if (window.navigator.msSaveOrOpenBlob) {
                //兼容 IE & EDGE
                navigator.msSaveBlob(blob, fileName);
            } else {
                var link = document.createElement('a');
                // 兼容不同浏览器的URL对象
                const url = window.URL || window.webkitURL || window.moxURL
                // 创建下载链接
                link.href = url.createObjectURL(blob);
                //命名下载名称
                link.download = fileName;
                //点击触发下载
                link.click();
                //下载完成进行释放
                url.revokeObjectURL(link.href);
            }
        },
        //下载模板---导出
        getQueryForm() {
            this.show = true
            getDownloadImport({}, this.userId, this.token).then(res => {
                // console.log(res.data)
                this.getOutExcel('下载.xls', res.data)
                 this.show = false
            })
        },
        warningQuerys(type) {
            if(!type){
               this.currentPage = 1
            }
            let data = {
                page: this.currentPage,
                limit: this.pageSize,
                deviceImei: '',
                deviceImsi: '',
            };
            console.log(data)
            getsimcardAdmin(data).then(res => {
                console.log(res.data.data.pages);
                this.tableData = res.data.data.records;
                this.recordCount = res.data.data.total;
            });
        },
        //分页首页
        first() {
            this.warningQuerys(false);
        },
        //当前页
        handleCurrentChange(val) {
            // console.log(val)
            this.currentPage = val;
            this.warningQuerys(true);
        },
        //分页最后一页
        last() {
            this.tableData = [];
            this.currentPage = Math.ceil(this.recordCount / 12);
            this.pages = Math.ceil(this.recordCount / 12)
            this.warningQuerys(true);
        },
        handleSelectionChange(val) {
            console.log(val, "val");
            this.multipleSelection = val;
            this.allocation = false;
            if (this.multipleSelection.length == 0) {
                this.allocation = true;
            }
        },
        tableRowClassName(row, rowIndex) {
            if (rowIndex == 0) return "headerStyle";
        },
        //新增按钮--取消事件  --完成
        remSubmission(params) {
            if (params == 1) {
                this.dialogFormVisible = false
            } else {
                this.dialogFlowVisible = false
            }
            this.clearForm()
        },
        //新增按钮--确定事件 --完成
        addSubmission() {
            this.dialogFormVisible = false
            this.show = true
            const data = this.form
            getaddSubmission(data).then((res) => {
                // console.log(res.data)
                if (res.data.resultCode == 0) {
                    this.$message({
                        message: "新增成功",
                        type: 'success'
                    });
                    this.show = false
                    this.clearForm()
                    this.warningQuerys(false);
                } else {
                    this.$message(res.data.errorDesc)
                    this.clearForm()
                    this.show = false
                }
            })
        },
        //清空表单  --ok
        clearForm() {
            this.form = {
                deviceImei: '',
                deviceImsi: '',
                deviceImsiEnd: '',
                deviceImsiStart: '',
                warnFlow: 1,
            }
        },
        //设置警告按钮事件 --完成
        alarmBatchReset() {
            this.dialogFlowVisible = true

        },
        tabdialogVisible() {
            this.dialogVisible = true
        },
        onChange(file, fileList) {
            this.fileList = fileList.slice(-1);
        },
        //xls文档导入新增
        httpRequest(params) {
            const form = new FormData();
            form.append("file", params.file);
            form.append("token", this.token);
            form.append("userId", this.userId);
            const config = {
                data: form
            };
            console.log(config)
            getImportNew(config, this.userId, this.token).then(({ data }) => {
                if (data.resultCode == 1) {
                    this.$message(data.errorDesc);
                    this.dialogVisible = false
                } else {
                    this.$message('导入成功');
                    this.dialogVisible = false
                    this.warningQuerys(false)
                }
            });
        },
        //导入弹框
        handleClose(done) {
            this.fileList = [];
            this.warningQuerys(false);
            // done();
        },
        //上传
        uploading() {
            this.dialogVisible = false;
            this.warningQuerys(false);
        },
        handleChange(value) {
            this.warnFlow = value
        },
        // 设置告警流量。--完成
        flowSubmission() {
            const data = {
                deviceImsiEnd: this.form.deviceImsiEnd,
                deviceImsiStart: this.form.deviceImsiStart,
                warnFlow: this.form.warnFlow
            }
            // console.log(data)
            getAlarmFlow(data, this.userId, this.token).then((res) => {
                console.log(res.data.resultCode)
                if (res.data.resultCode == 0) {
                    this.$message({
                        message: '设置成功',
                        type: 'success'
                    });
                    this.dialogFlowVisible = false
                    this.warningQuerys(false)
                    this.clearForm()
                } else {
                    this.$message(res.data.errorDesc);
                    this.dialogFlowVisible = false
                    this.warningQuerys(false)
                    this.clearForm()
                }
            })
        },
        //批量删除
        handleDelete(value) {
            // console.log(value.id)
            this.$confirm("确认删除?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.del(value.id)
            }).catch(()=>{
                this.warningQuerys(false);
            })
        },
        //请求事件
        del(ids) {
            deleteDeviceBatch(ids, this.userId, this.token).then((data) => {
                // console.log(data.data.resultCode)
                if (data.data.resultCode == 0) {
                    this.$message.success("操作成功");
                    this.warningQuerys(false);
                } else {
                    this.$message.error(data.desc);
                }
            });
        }

    }
};
</script>
  
<style scoped>
.table span {
    cursor: pointer;
}

.edit {
    font-size: 14px;
    color: #6e7790;
    margin-right: 10px;
}

.edit:hover {
    color: #0cc;
}

.icon {
    margin-bottom: 0;
}

a {
    text-decoration: none !important;
    color: #606266;
}

/*分页样式*/
.app-page-container {
    margin-top: 20px;
}

.app-page-container .el-pagination {
    display: inline-block;
}

.app-page-container span {
    cursor: pointer;
    color: #616161;
    background: #fff;
    border: 1px solid #e8eaf0;
    height: 32px;
    line-height: 32px;
    display: inline-block;
    text-align: center;
    transition: 0.1s;
    vertical-align: top;
    border-radius: 16px;
    font-size: 12px;
    width: 56px;
}

.app-page-container span:hover {
    background: #00cccc;
    color: #fff;
    border-color: #00cccc;
}

.condition_item1 {
    /*width: 840px;*/
    margin-bottom: 20px;
}

.condition_item1_input {
    width: 246px;
}

/deep/ el-table>>>.headerStyle th {
    background: #8c939d;
}



/deep/ #faultState .el-select {
    display: inline-block;
    position: relative;
    width: 110px;
    margin-left: 14px;
}

/deep/ #timeSpan .el-select {
    display: inline-block;
    position: relative;
    width: 110px;
    margin-left: 14px;
}

.buttons {
    width: 96px;
}

.table {
    margin-top: 20px;
}

/deep/ .el-table .cell {
    white-space: pre-line;
}

/*/deep/ .el-table td, .el-table th{*/
/*    padding: 0;*/
/*}*/
.paging {
    margin-top: 20px;
}

/deep/.el-slider__marks-text {
    word-break: normal;
}

.slider {
    width: 300px;
    padding-bottom: 20px;
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
}

.buttons_download {
    width: 147px;
}

.common {
    font-size: 14px;
}

.during_time {
    margin: 20px;
}

.first {
    padding-top: 10px;
}

.rowline {
    margin: 10px 20px 0;
}
</style>
  