import axios from "../utils/request";

//sim总数据
export function getsimcardAdmin(data) {
    return axios({
      url: "/clodchainAM/web/sim/page",
      method: "get",
      params: data
    });
  }

  //sim-新增接口 POST /sim/add
  export function getaddSubmission(data) {
    // console.log(data)
    return axios({
      url: "/clodchainAM/web/sim/add",
      method: "post",
      data: data
    });
  }

  //sim-导入新增 getImportNew 完成
export function getImportNew(data,useId,token) {
  return axios({
    url: "/clodchainAM/web/sim/import?userId="+useId+"&token="+token,
    method: "post",
    ...data,
  });
}

  //sim-下载导入模版接口 POST /sim/downLoad_template
  export function getDownloadImport(data,useId,token) {
    return axios({
      url: "/clodchainAM/web/sim/downLoad_template?userId="+useId+"&token="+token,
      method: "post",
      data,
      responseType: "blob"
    });
  }

  //sim-设置警告流量模版接口 POST /sim/set_warn_flow
  export function getAlarmFlow(data,useId,token) {
    return axios({
      url: "/clodchainAM/web/sim/set_warn_flow?userId="+useId+"&token="+token,
      method: "post",
      data,
    });
  }

  //批量删除
  export function deleteDeviceBatch(ids,useId,token){
    console.log(ids,useId,token)
    return axios({
      url: "/clodchainAM/web/sim/delete_sim?userId="+useId+"&token="+token,
      method: "post",
      data:{
        ids:ids+""
      },
      // responseType: "blob"
    });
  }